import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams, useNavigate } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';

import GameContainer from './GameContainer';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

const Schedule = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [league, setLeague] = useState({
    games: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {

    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.filter((game) => game.season == SEASON).sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    setLeague(league);
    setDataReady(true);
  }

  function getTeamForID(id) {
    return league.teams.filter((team) => team.id == id)[0];
  }

  function displayFinishedGameThisWeek (game) {
    return (
      <GameContainer league={league} gameID={game.id}></GameContainer>
    )
  }

  // MOVED TO UTILS.JS
  function abbvWeekday (idx) {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekday[idx];
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }


    return (
      <div className='league-game-container' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-scores'>
          <div className='league-game-team-names'>
            <p className={ game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away-name'}>{ away_team.name.toUpperCase() }</p>
            <p className={ game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home-name'}>{ home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-team-records'>
            <p className='league-game-team-record' key={game.id + '-away-record'}>{ getRecordForID(game.away_team_id) }</p>
            <p className='league-game-team-record' key={game.id + '-home-record'}>{ getRecordForID(game.home_team_id) }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'>{ abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          <p className='league-game-time'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
          <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
        </div>
      </div>
    )
  }

  function displayGames () {
    let today = Date.now();
    let oneWeek = today + (7 * 24 * 60 * 60 * 1000);
    let gamesThisWeek;
    let finishedGames;
    let allGames;
    if (id) {
      gamesThisWeek = league.games.filter((game) => (!['completed', 'postponed'].includes(game.status) && game.date > today) && (game.away_team_id === id || game.home_team_id === id) && (game.away_team_id !== '' && game.home_team_id !== ''))
      finishedGames = league.games.filter((game) => (['completed', 'postponed'].includes(game.status) || game.date <= today) && (game.away_team_id === id || game.home_team_id === id) && (game.away_team_id !== '' && game.home_team_id !== ''))
      allGames = league.games.filter((game) => (game.away_team_id === id || game.home_team_id === id))
    } else {
      gamesThisWeek = league.games.filter((game) => !['completed', 'postponed'].includes(game.status) && game.date > today && (game.away_team_id !== '' && game.home_team_id !== ''))
      finishedGames = league.games.filter((game) => game.date <= today || ['completed', 'postponed'].includes(game.status) )
      allGames = league.games
    }
    if (gamesThisWeek.length == 0 && finishedGames.length < allGames.length) {
      return <p>No upcoming games this week</p>
    }
    let sortedGamesThisWeek = gamesThisWeek.sort((a, b) => (a.date < b.date) ? -1 : 1)
    let sortedFinishedGames = finishedGames.sort((a, b) => (a.date < b.date) ? -1 : 1)

    let gameElements = sortedGamesThisWeek.map((game, place) => displayGameThisWeek(game) )
    let gameOverElements = sortedFinishedGames.map((game, place) => displayFinishedGameThisWeek(game) )
    return (
      <>
        {gameOverElements}
        {gameElements}
      </>
    );
  }

  // MOVED TO UTILS.JS

  function getRecordForID (given_id = null) {
    let team_id = given_id === null ? id : given_id

    if (team_id) {
      let allGames = league.games.filter((game) => game.status === 'completed' && game.season == SEASON  && (game.away_team_id === team_id || game.home_team_id === team_id))
      let allGamesWon = league.games.filter((game) => game.status === 'completed' && game.season == SEASON && (game.winner === team_id))
      let gamesPlayed = allGames.length;
      let gamesWon = allGamesWon.length;
      return `${gamesWon}-${gamesPlayed - gamesWon}`
    } else {
      return '';
    }
  }


  function displayDropdown (team) {
    return (
      <>
        { !id && <li><a className="dropdown-item"  href={"/schedule/" + team.id }>{ team.name.toUpperCase()}</a></li> }
        { id && id === team.id && <li><a className="dropdown-item"  href={"/schedule/" + team.id }><b>{ team.name.toUpperCase()}</b></a></li> }
        { id && id !== team.id && <li><a className="dropdown-item"  href={"/schedule/" + team.id }>{ team.name.toUpperCase()}</a></li> }
      </>
    )
  }
  function displayTeamNameDropdown () {
    let teamDropdownElements = league.teams.map((team, place) => displayDropdown(team))
    return teamDropdownElements;
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Schedule</p>
            <div className="dropdown league-dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="teamScheduleDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                { id ? getTeamForID(id).name.toUpperCase() : "Select a team" }
              </button>
              <ul className="dropdown-menu" aria-labelledby="teamScheduleDropdown">
                { displayTeamNameDropdown() }
              </ul>
            </div>
            { id && <a className="league-type league-mini-header-link" href="/schedule">All Games</a> }
            { id && <p className="league-title-standard-small text-align-center no-margin">Record: {getRecordForID()}</p>}
            <div className="league-table-container schedule">
              { league.games.length > 0 ? displayGames() : 'Waiting for games to be added to website...'}
            </div>
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default Schedule;
