import React, { Component, useRef, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';
import GameContainer from './GameContainer';
import * as utils from '../common/Utils.js'
import { getAuth } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase';

const auth = getAuth();
const user = auth.currentUser

const SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SHOW_BLOCKS_AND_STEALS = true;
const playoffRoundTagNames = ['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']
const playoffRoundNames = {
  'playin': 'Play-In',
  'firstround': 'First Round',
  'quarterfinals': 'Quarterfinals',
  'semifinals': 'Semifinals',
  'championship': 'Championship',
}
const playoffRoundClasses = {
  'playin': 'round_1_of_5',
  'firstround': 'round_2_of_5',
  'quarterfinals': 'round_3_of_5',
  'semifinals': 'round_4_of_5',
  'championship': 'round_5_of_5',
}
const CURRENT_ROUND = utils.getCurrentPlayoffRound();

// Used for registering swipe actions:
let startOfSwipeX = 0;
let endOfSwipeX = 0;
let startOfSwipeY = 0;
let endOfSwipeY = 0;

const Homepage = () => {
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [showOverallStandings, setShowOverallStandings] = useState(true);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    // uncomment to sync test with production
    // if (DATABASE_SOURCE == production) {
    //   syncTestDatabaseWithProduction(league)
    // }

    // utils.syncTestDatabaseWithProduction(league)

    // DESTRUCTIVE ACTION! uncomment to sync production with test !!!!!!!!!!
    // if (DATABASE_SOURCE == test) {
    //   syncProdDatabaseWithTest(league)
    // }

    // uncomment to add playoff games to the database
    // addSeason2PlayoffGamesToDatabase(league)

    // Only use games that are in this season
    const sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    // Only use game stats from this season
    // Don't limit this for now...
    // const statsThisSeason = league.gameStats.filter((stat) => getGameForID(stat.game_id).season == SEASON)
    // league.gameStats = statsThisSeason

    // Only use teams that are in this season
    let teams = league.seasonTeams.filter((team) => team.season == SEASON)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    setDataReady(true);
  }

  async function addSeason2PlayoffGamesToDatabase (league) {
    let playoffGames = league.games.filter((game) => game.type == 'playoff' && game.season == 2)

    if (playoffGames.length > 0) {
      return;
    }
    let playoffGameDefault = {
      away_team_id: '',
      home_team_id: '',
      playoff_tags: [],
      referees: [],
      score: {
        away: 0,
        home: 0
      },
      season: 2,
      type: 'playoff',
      winner: ''
    }

    // 10v11
    let playin10v11 = { ...playoffGameDefault }
    playin10v11.playoff_tags = ['playin', '10v11'];
    playin10v11.away_team_placeholder = '10th Seed';
    playin10v11.home_team_placeholder = '11th Seed';
    playin10v11.date = Timestamp.fromDate(new Date("August 12, 2024 6:30"))
    playin10v11.id = createNewIDForGame();

    // 9v12 play-in
    let playin9v12 = { ...playoffGameDefault }
    playin9v12.playoff_tags = ['playin', '9v12'];
    playin9v12.away_team_placeholder = '9th Seed';
    playin9v12.home_team_placeholder = '12th Seed';
    playin9v12.date = Timestamp.fromDate(new Date("August 12, 2024 19:30"))
    playin9v12.id = createNewIDForGame();

    // 4vlow_playin
    let firstround4vlow_playin = { ...playoffGameDefault }
    firstround4vlow_playin.playoff_tags = ['firstround', '4vlow_playin'];
    firstround4vlow_playin.away_team_placeholder = '4th Seed';
    firstround4vlow_playin.playoff_round_order = 1
    firstround4vlow_playin.date = Timestamp.fromDate(new Date("August 13, 2024 6:30"))
    firstround4vlow_playin.id = createNewIDForGame();

    // 3vhigh_playin
    let firstround3vhigh_playin = { ...playoffGameDefault }
    firstround3vhigh_playin.playoff_tags = ['firstround', '3vhigh_playin'];
    firstround3vhigh_playin.away_team_placeholder = '3rd Seed';
    firstround3vhigh_playin.playoff_round_order = 3
    firstround3vhigh_playin.date = Timestamp.fromDate(new Date("August 13, 2024 19:30"))
    firstround3vhigh_playin.id = createNewIDForGame();

    // 6v7
    let firstround6v7 = { ...playoffGameDefault }
    firstround6v7.playoff_tags = ['firstround', '6v7'];
    firstround6v7.away_team_placeholder = '6th Seed';
    firstround6v7.home_team_placeholder = '7th Seed';
    firstround6v7.playoff_round_order = 2
    firstround6v7.date = Timestamp.fromDate(new Date("August 14, 2024 6:30"))
    firstround6v7.id = createNewIDForGame();

    // 5v8
    let firstround5v8 = { ...playoffGameDefault }
    firstround5v8.playoff_tags = ['firstround', '5v8'];
    firstround5v8.away_team_placeholder = '5th Seed';
    firstround5v8.home_team_placeholder = '8th Seed';
    firstround5v8.playoff_round_order = 4
    firstround5v8.date = Timestamp.fromDate(new Date("August 14, 2024 19:30"))
    firstround5v8.id = createNewIDForGame();

    // XXXvYYY
    let quarterfinals1 = { ...playoffGameDefault }
    quarterfinals1.playoff_tags = ['quarterfinals',
      "winner_" + firstround4vlow_playin.id,
      "winner_" + firstround6v7.id
    ];
    quarterfinals1.date = Timestamp.fromDate(new Date("August 15, 2024 6:30"))
    quarterfinals1.id = createNewIDForGame();

    // AAAvBBB
    let quarterfinals2 = { ...playoffGameDefault }
    quarterfinals2.playoff_tags = ['quarterfinals',
      "winner_" + firstround3vhigh_playin.id,
      "winner_" + firstround5v8.id
    ];
    quarterfinals2.date = Timestamp.fromDate(new Date("August 15, 2024 19:30"))
    quarterfinals2.id = createNewIDForGame();

    // 2vCCC
    let semifinals1 = { ...playoffGameDefault }
    semifinals1.playoff_tags = ['semifinals',
      '2',
      "winner_" + quarterfinals2.id
    ];
    semifinals1.away_team_placeholder = '2nd Seed';
    semifinals1.playoff_round_order = 2
    semifinals1.date = Timestamp.fromDate(new Date("August 16, 2024 6:30"))
    semifinals1.id = createNewIDForGame();

    // 1vZZZ
    let semifinals2 = { ...playoffGameDefault }
    semifinals2.playoff_tags = ['semifinals',
      '1',
      "winner_" + quarterfinals1.id
    ];
    semifinals2.away_team_placeholder = '1st Seed';
    semifinals2.playoff_round_order = 1
    semifinals2.date = Timestamp.fromDate(new Date("August 16, 2024 19:30"))
    semifinals2.id = createNewIDForGame();

    // DDDvWWW
    let championship = { ...playoffGameDefault }
    championship.playoff_tags = ['championship',
      "winner_" + semifinals1.id,
      "winner_" + semifinals2.id
    ];
    championship.date = Timestamp.fromDate(new Date("August 18, 2024 14:30"))
    championship.id = createNewIDForGame();

    league.games.push(playin10v11)
    league.games.push(playin9v12)
    league.games.push(firstround4vlow_playin)
    league.games.push(firstround3vhigh_playin)
    league.games.push(firstround6v7)
    league.games.push(firstround5v8)
    league.games.push(quarterfinals1)
    league.games.push(quarterfinals2)
    league.games.push(semifinals1)
    league.games.push(semifinals2)
    league.games.push(championship)

    await setDoc(doc(db, "league", test), league);
  }

  async function addPlayoffGamesToDatabase (league) {
    let oneSemiFinalGame = league.games.filter((game) => game.type == 'playoff')
    if (oneSemiFinalGame.length !== 1) {
      return;
    }
    oneSemiFinalGame = oneSemiFinalGame[0];

    let oneDay = (1 * 24 * 60 * 60 * 1000);

    // 2v7
    let game2 = { ...oneSemiFinalGame }
    game2.playoff_tags = ['quarterfinal', '2v7'];
    game2.away_team_id = 'francis-xavier';
    game2.home_team_id = 'isaac-jogues';

    // 3v6
    let game3 = { ...oneSemiFinalGame }
    game3.playoff_tags = ['quarterfinal', '3v6'];
    game3.away_team_id = 'augustine';
    game3.home_team_id = 'pius-v';

    // 4v5
    let game4 = { ...oneSemiFinalGame }
    game4.playoff_tags = ['quarterfinal', '4v5'];
    game4.away_team_id = 'maximilian-kolbe';
    game4.home_team_id = 'john-paul-ii';

    league.games.push(game2)
    league.games.push(game3)
    league.games.push(game4)

    // await setDoc(doc(db, "league", 'league_data_test'), league);
  }

  function getCompletedGames () {
    return league.games.filter((game) => game.status == 'completed' && game.season == SEASON && game.type == 'regular_season')
  }

  function getRecordForID (given_id) {
    let team_id = given_id
    if (team_id) {
      let allGames = league.games.filter((game) => game.status === 'completed' && game.season === SEASON && (game.away_team_id === team_id || game.home_team_id === team_id))
      let allGamesWon = league.games.filter((game) => game.status === 'completed' && game.season === SEASON && (game.winner === team_id))
      let gamesPlayed = allGames.length;
      let gamesWon = allGamesWon.length;
      return `${gamesWon}-${gamesPlayed - gamesWon}`
    } else {
      return '';
    }
  }

  function getStatsForTeams (teams) {
    let teamData = {};
    teams.forEach((team) => teamData[team.id] = {
      wins: 0,
      losses: 0,
      games: 0,
      streak: 0,
      pointsAgainst: 0,
      pointsFor: 0
    })

    let completedGames = getCompletedGames();

    let sortedCompletedGames = completedGames.sort((a, b) => (a.date < b.date) ? -1 : 1)
    sortedCompletedGames.forEach((game) => {
      let winner = game.winner;
      let loser = game.winner == game.away_team_id ? game.home_team_id : game.away_team_id;

      let winnerScore = game.score.away > game.score.home ? game.score.away : game.score.home;
      let loserScore = game.score.away > game.score.home ? game.score.home : game.score.away;

      if (teamData[winner]) {
        teamData[winner].wins += 1;
        teamData[winner].games += 1;
        teamData[winner].pointsAgainst += loserScore;
        teamData[winner].pointsFor += winnerScore;
        if (teamData[winner].streak < 0) {
          teamData[winner].streak = 1;
        } else {
          teamData[winner].streak += 1;
        }
      }

      if (teamData[loser]) {
        teamData[loser].losses += 1;
        teamData[loser].games += 1;
        teamData[loser].pointsAgainst += winnerScore;
        teamData[loser].pointsFor += loserScore;
        if (teamData[loser].streak > 0) {
          teamData[loser].streak = -1;
        } else {
          teamData[loser].streak -= 1;
        }
      }
    })

    let defaultStats =  {
      pointsFor: '0',
      pointsAgainst: '0',
      streak: '-',
      wins: '0',
      losses: '0',
      games: '0',
      winPercentage: '.000',
      winPercentageValue: 0
    };

    let newTeamList = [];
    teams.forEach((team) => {
      let t = teamData[team.id];
      if (t.wins === t.games && t.games > 0) {
        t.winPercentage = '1.000';
        t.winPercentageValue = 1;
      } else if(t.wins + t.losses !== 0){
        t.winPercentage = (t.wins / t.games).toFixed(3).toString().slice(1);
        t.winPercentageValue = t.wins / t.games
      }

      let existingTeamData = team;
      let stats = {...defaultStats, ...teamData[team.id]};
      existingTeamData.stats = stats;

      newTeamList.push(existingTeamData);
    })

    return newTeamList;
  }

  function printStreak (streak) {
    if (streak === 0) {
      return '-';
    }
    else if (streak < 0) {
      return Math.abs(streak).toString() + 'L'
    } else {
      return streak.toString() + 'W'
    }
  }

  function displayTeam(team, place) {
    let stats = team.stats;

    return (
        <tr id={team.id + "-team-row"}>
          <td className="league-table-data-name user-name no-wrap"><span>{ place + '. ' }</span><a className="unstyled-link" href={"/teams/" + team.id }>{ team.name.toUpperCase() }</a></td>
          <td className="league-table-data desktop-only"><span>{ stats.wins }</span></td>
          <td className="league-table-data desktop-only"><span>{ stats.losses }</span></td>
          <td className="league-table-data wide-fixed mobile-only"><span>{ stats.wins }-{ stats.losses }</span></td>
          <td className="league-table-data wide"><span>{ stats.winPercentage }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ stats.pointsFor }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ stats.pointsAgainst }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ stats.pointsFor - stats.pointsAgainst }</span></td>
          <td className="league-table-data wide-fixed desktop-only"><span>{ printStreak(stats.streak) }</span></td>
        </tr>
    );
  }

  function headToHeadValue (a,b) {
    let gamesAgainstEachother = league.games.filter((game) => game.status == 'completed' && game.season == SEASON && game.type == 'regular_season' && ((game.away_team_id == a.id && game.home_team_id == b.id) || (game.away_team_id == b.id && game.home_team_id == a.id)))
    let gamesTeamAWon = gamesAgainstEachother.filter((game) => game.winner == a.id)

    if (gamesAgainstEachother.length == 0) {
      return 0;
    } else if (gamesTeamAWon.length / gamesAgainstEachother.length < .5) {
      return 1;
    } else if (gamesTeamAWon.length / gamesAgainstEachother.length > .5) {
      return -1;
    } else {
      return 0;
    }
  }

  function rankTeams (teams) {
    teams = getStatsForTeams(teams);

    // Ranking by Win Percentage

    let teams_sorted = teams.sort((a, b) => {
      if (a.stats.winPercentageValue > b.stats.winPercentageValue) { // .321 team > .123 team
        return -1;
      } else if (a.stats.winPercentageValue < b.stats.winPercentageValue) { // .123 team < .321 team
        return 1;
      } else if (a.stats.losses > b.stats.losses) { // 0-1 teams > 0-2 teams
        return 1;
      } else if (a.stats.losses < b.stats.losses) { // 0-2 teams < 0-1 teams
        return -1;
      } else if (a.stats.games > b.stats.games) { // 2-0 teams > 1-0 teams
        return -1;
      } else if (a.stats.games < b.stats.games) { // 1-0 teams < 2-0 teams
        return 1;
      } else {
        // Tiebreaker #1 - Head to Head
        let headToHead = headToHeadValue(a,b);
        if (headToHead !== 0) {
          return headToHead;
        } else {
          // Tiebreaker #2 - Point Differential +/-
          let pointDifferentialTeamA = a.stats.pointsFor - a.stats.pointsAgainst;
          let pointDifferentialTeamB = b.stats.pointsFor - b.stats.pointsAgainst;
          if (pointDifferentialTeamA > pointDifferentialTeamB) {
            return -1;
          } else if (pointDifferentialTeamA > pointDifferentialTeamB) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    })

    // update this seed data when submitting scores in admin view
    // for(let i = 0; i < teams_sorted.length; i++) {
    //   teams_sorted[i]['seed'] = i+1;
    // }
    // console.log(teams_sorted)
    // league.teams = teams_sorted
    // await setDoc(doc(db, "league", 'league_data_test'), league);

    return teams_sorted;
  }

  function displayTeams (teams) {
    if (!teams){
      return;
    }
    let sorted_teams = rankTeams(teams);
    sorted_teams.map((team, place) => team.seed = place + 1)

    let team_elements = sorted_teams.map((team, place) => displayTeam(team, place+1) )

    return team_elements;
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let gameTBD = false;

    let away_team;
    let home_team;

    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    return (
      <div className="league-game-container-homepage"  onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <p className="league-title-skinny-small" key={game.id + "-date"}>{ game.date.toDateString() } @ { hours }:{ game.date.getMinutes() } { hours_label }</p>
        <p className="league-game-location" key={game.id + "-location"}>{ utils.getLocationName(game.location) }</p>
        <p key={game.id}>{ away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
      </div>
    )
  }

  function anyGamesInLastDay () {
    let today = Date.now();
    let oneDayAgo = today - (1 * 24 * 60 * 60 * 1000);

    let gamesInTheLastDay = league.games.filter((game) => game.date < today && game.date > oneDayAgo && game.status == 'completed')
    return gamesInTheLastDay.length > 0;
  }

  function displayFinishedGamesLastDay () {
    let today = Date.now();
    let oneDayAgo = today - (1 * 24 * 60 * 60 * 1000);

    let gamesInTheLastDay = league.games.filter((game) => game.date < today && game.date > oneDayAgo && game.status == 'completed')
    let gameElements = gamesInTheLastDay.map((game) => displayFinishedGameThisWeek(game) )
    return gameElements;
  }

  function displayGamesThisWeek () {
    let today = Date.now();
    let sevenDays = today + (7 * 24 * 60 * 60 * 1000);

    let gamesThisWeek = league.games.filter((game) => game.date > today && game.date < sevenDays && !['completed', 'postponed'].includes(game.status))
    let finishedGames = league.games.filter((game) => game.date <= today || game.status === 'completed' )

    if (league.games == 0) {
      return <p>Waiting for games to be added to website...</p>
    }
    if (gamesThisWeek.length == 0 && finishedGames.length < 40) {
      return <p>No upcoming games in the next week</p>
    }
    let sortedGamesThisWeek = gamesThisWeek.sort((a, b) => (a.date < b.date) ? -1 : 1)

    let gameElements = sortedGamesThisWeek.map((game, place) => displayGameThisWeek(game) )
    return gameElements;
  }

  function abbvWeekday (idx) {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekday[idx];
  }

  function displayFinishedGameThisWeek (game) {
    return (
      <GameContainer league={league} gameID={game.id}></GameContainer>
    )
  }

  function displayPlayoffGame (game) {
    let gameTBD = false;

    let away_team;
    let home_team;
    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    let today = Date.now();


    return (
      <GameContainer league={league} gameID={game.id}></GameContainer>
    )
  }

  function displayPlayoffGames (round) {
    // let games = []
    // let rankedTeams = league.teams
    // let totalTeams = rankedTeams.length

    // let roundNameMap = {
    //   '4': 'quarterfinal',
    //   '2': 'semifinal',
    //   '1': 'championship'
    // }
    // let roundName = roundNameMap[numberOfGames.toString()]

    let gamesThisRound = league.games.filter((game) => game.type == 'playoff' && game.playoff_tags && game.playoff_tags.includes(round) && game.season == SEASON);

    let sortedGames = gamesThisRound.sort(function(a,b) {
      let orderA = a.playoff_round_order || 0;
      let orderB = b.playoff_round_order || 0;
      if (orderA < orderB) {
        return -1;
      } else if (orderB < orderA) {
        return 1;
      } else {
        return 0;
      }
    })

    // let gamesThisRoundWithTeams = gamesThisRound.filter((game) => game.away_team_id !== '' && game.home_team_id !== '')
    // let gamesThisRoundWithoutTeams = gamesThisRound.filter((game) => game.away_team_id == '' || game.home_team_id == '')
    let gameElements = sortedGames.map((game, place) => displayPlayoffGame(game))
    return (
      <>
        {gameElements}
      </>
    );
  }

  function displayPlayoffsButtons () {
    let playoffButtons = [];

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds;
    for (let i = playoffRounds; i < playoffRoundTagNames.length; i++) {
      let playoffRoundTagName = playoffRoundTagNames[i];
      let playoffRoundName = playoffRoundNames[playoffRoundTagName];

      let buttonClass = '';
      if (league.teams.length == 8){
        if (playoffRoundTagName == 'championship') {
          buttonClass = 'btn-dark';
        }
      } else if (league.teams.length == 12) {
        if (playoffRoundTagName == CURRENT_ROUND) {
          buttonClass = 'btn-dark';
        }
      }

      let roundClassName = "round_" + (i + 1 - playoffRounds) + "_of_" + numberPlayoffRounds

      playoffButtons.push(
        <>
          <div className='league-bracket-round-button'>
            <a id={ playoffRoundTagName + "Button" } type="button" className={ "btn " + buttonClass } onClick={ () => { clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds) } }>{ playoffRoundName }</a>
          </div>
        </>
      )
    }

    return (
      <div className='league-bracket-round-buttons scroll-on-overflow'>
        { playoffButtons }
      </div>
    )
  }

  function clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds){
    let leagueBracket = document.getElementsByClassName('league-bracket')[0];
    leagueBracket.classList = '';
    leagueBracket.classList.add('league-bracket');
    leagueBracket.classList.add(roundClassName);

    let button = document.getElementById(playoffRoundTagName + 'Button');
    button.classList.add('btn-dark')

    for (let j = playoffRounds; j < playoffRoundTagNames.length; j++) {
      let playoffOtherRoundTagName = playoffRoundTagNames[j];
      if (playoffRoundTagName == playoffOtherRoundTagName) {
        continue;
      }

      let playoffRoundButton = document.getElementById(playoffOtherRoundTagName + 'Button');
      playoffRoundButton.classList.remove('btn-dark')
    }
  }

  function displayPlayoffs () {
    return (
      <>
        { displayPlayoffRounds() }
      </>
    );
  }

  function swipePlayoffRound (swipeDirection, rounds) {
    let playinButton = document.getElementById('playinButton');
    let firstroundButton = document.getElementById('firstroundButton');
    let quarterfinalsButton = document.getElementById('quarterfinalsButton');
    let semifinalsButton = document.getElementById('semifinalsButton');
    let championshipButton = document.getElementById('championshipButton');

    let roundButtons = [playinButton, firstroundButton, quarterfinalsButton, semifinalsButton, championshipButton]

    for (let i = 0; i < roundButtons.length; i++) {
      let roundButton = roundButtons[i]
      if (roundButton.classList.contains('btn-dark')){
        if (swipeDirection == 'right' && i >= roundButtons.length - 1) {
        } else if (swipeDirection == 'left' && i <= roundButtons.length - rounds) {
        } else if (swipeDirection == 'left') {
          roundButtons[i-1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i-1].click();
        } else if (swipeDirection == 'right') {
          roundButtons[i+1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i+1].click();
        }
        break;
      }
    }
  }

  function checkDirectionOfSwipe (rounds) {
    if (Math.abs(startOfSwipeX - endOfSwipeX) < 10) {
      return;
    }
    if (Math.abs(startOfSwipeX - endOfSwipeX) < Math.abs(startOfSwipeY - endOfSwipeY)) {
      return;
    }

    if (endOfSwipeX < startOfSwipeX) {
      swipePlayoffRound('right', rounds)
    } else if (endOfSwipeX > startOfSwipeX) {
      swipePlayoffRound('left', rounds)
    }
  }

  function displayPlayoffRounds () {
    let divClass = '';
    if (SEASON == 1){
      return (
        <div className='league-bracket round_3_of_3' onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 3
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    } if (SEASON == 2){
      return (
        <div className={ `league-bracket ${playoffRoundClasses[CURRENT_ROUND]}` } onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 5
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    }
  }

  function displayPlayoffsForRounds(rounds) {
    let playoffBracket = rounds.map((round) => displayPlayoffRound(round))

    return (
      <>
        { playoffBracket }
      </>
    );
  }

  function displayPlayoffRound(round) {
    let roundNameMap = {
      'playin': 'Play-In',
      'firstround': 'First Round',
      'quarterfinals': 'Quarterfinals',
      'semifinals': 'Semifinals',
      'championship': 'Championship'
    }
    const roundName = roundNameMap[round]

    let roundLocation = 'South Boston Catholic Academy'

    if (SEASON == 1) {
      let roundLocationMap = {
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'Joseph P. Tynan School',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    } else if (SEASON == 2) {
      let roundLocationMap = {
        'playin': 'South Boston Catholic Academy',
        'firstround': 'South Boston Catholic Academy',
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'South Boston Catholic Academy',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    }

    return (
      <div className='league-bracket-round'>
        <h4 className='text-align-center'>{ roundName }</h4>
        <p className='text-align-center'>{ roundLocation }</p>
        <div className='league-bracket-round-games'>
          { displayPlayoffGames(round) }
        </div>
      </div>
    )
  }

  function createNewIDForGame () {
    let dateString = Date.now().toString(36);
    let randomness = Math.random().toString(36).substr(2);
    let gameID = dateString + randomness;

    return gameID;
  }

  function showAverageStatRow(player, stat) {
    let statLabelAbbv = {
      'assists': 'APG',
      'points': 'PPG',
      'rebounds': 'RPG',
      'blocks': 'BPG',
      'steals': 'SPG'
    }
    let p = utils.getPlayerByID(league.players, player.player_id)
    let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, p.player_id)
    return (
      <div className='league-game-player-stat-group'>
        <p className='league-game-player-name' key={player.player_id + '-player-name'}><a className="unstyled-link" href={"/players/" + player.player_id }>{ utils.displayPlayerName(p) }</a> <span className='league-season-average-label'>({ playerTeam.name })</span></p>
        <p className='league-game-player-stat' key={player.player_id + '-player-stat'}>{ parseFloat(player.average.toFixed(1)) } <span className='league-season-average-label'>{ statLabelAbbv[stat] }</span></p>
      </div>
    )
  }
  function getGameForID (game_id){
    return league.games.find((game) => game.id == game_id);
  }
  function getStatsForPlayer (player_id, season, withStealsAndBlocks) {
    let allStatsForPlayer;
    if (withStealsAndBlocks) {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && getGameForID(gameStat.game_id).date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED && gameStat.status == 'played')
    } else {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && gameStat.status == 'played')
    }

    if (season == 0) {
      return allStatsForPlayer;
    } else {
      return allStatsForPlayer.filter((stats) => getGameForID(stats.game_id).season == season)
    }
  }
  function showTopSeasonStats (stat, season = 0) {
    let currentPlayers = league.teams.map((team) => team.players.map((id) => utils.getPlayerByID(league.players, id))).flat()
    // Known limitation - there are a few players who have stats but are not in the players table
    // They cannot show up in the top season stats. This if fine because they don't anyways.
    // In the future, we may want to allow this or just create player entries for them.
    currentPlayers = currentPlayers.filter((p) => p !== undefined)

    let playerAverages = []

    currentPlayers.forEach((p) => {
      let allStatsForPlayer;
      if (stat == 'blocks' || stat == 'steals') {
        allStatsForPlayer = getStatsForPlayer(p.player_id, season, true);
      } else {
        allStatsForPlayer = getStatsForPlayer(p.player_id, season, false);
      }

      if (allStatsForPlayer.length == 0) {
        return;
      }

      let average = allStatsForPlayer.reduce(function (avg, player, _, { length }) {
        // Don't show players with less than half games played (on average)
        // TODO: Dynamically program this so I don't need to edit this throughout the season
        let completedGames = getCompletedGames();

        if (length < 5 && completedGames.length > 40) {
          return 0;
        }

        return avg + player[stat] / length;
      }, 0);

      let playerAverage = {
        'player_id': p.player_id,
        'name': utils.displayPlayerName(p),
        'average': average
      };
      if (!isNaN(average)) {
        playerAverages.push(playerAverage)
      }
    })

    let topThreePlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 3);

    let topFivePlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 5);

    let topTenPlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 10);

    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    // Choose between top 3, top 5, or top 10 players to show
    // let topPlayerAverages = topThreePlayerAverages;
    // let topPlayerAverages = topFivePlayerAverages;
    let topPlayerAverages = topTenPlayerAverages;

    if (topPlayerAverages.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center">{ statLabel[stat] }</p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { topPlayerAverages.map((player) => showAverageStatRow(player, stat) ) }
            </div>
          </div>
        </div>
      )
    }
  }

  function showStandings (teams) {
    return (
      <div className="league-table-container scroll-on-overflow">
        <table className="table league-table">
          <thead className="league-table-heading-hole">
            <tr>
              <th className="league-table-heading-name"><span>TEAM</span></th>
              <th className="league-table-heading hole"><span>W</span></th>
              <th className="league-table-heading hole"><span>L</span></th>
              <th className="league-table-heading hole"><span>PCT</span></th>
              <th className="league-table-heading hole"><span>PF</span></th>
              <th className="league-table-heading hole"><span>PA</span></th>
              <th className="league-table-heading hole"><span>+/-</span></th>
              <th className="league-table-heading hole"><span>Strk</span></th>
            </tr>
          </thead>
          <thead className="league-table-heading-total">
            <tr>
              <th className="league-table-heading-name"><span>TEAM</span></th>
              <th className="league-table-heading-small"><span>W - L</span></th>
              <th className="league-table-heading-small"><span>PCT</span></th>
            </tr>
          </thead>
          <tbody>
            { displayTeams(teams) }
          </tbody>
        </table>
      </div>
    )
  }

  function displayOverallStandings () {
    return (
      <div>
        <p className="league-title-standard-secondary text-align-center">League Morning Star</p>
        { showStandings(league.teams) }
      </div>
    )
  }

  function displayConferenceStandings (conference) {
    let conferenceTeams = league.teams.filter((team) => team.conference == conference)
    let conferenceTitle = conference == 'east' ? 'Eastern Conference' : 'Western Conference'

    return (
      <div>
        <p className="league-title-standard-secondary text-align-center">{ conferenceTitle }</p>
        { showStandings(conferenceTeams) }
      </div>
    )
  }

  function toggleToShowLeagueStandings () {
    setShowOverallStandings(true);
  }
  function toggleToShowConferenceStandings () {
    setShowOverallStandings(false);
  }

  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-header">
            <div className="league-header-top">
              <div className="league-header-logo">
                <img className="league-logo" src="league.png"></img>
                <a id="mass-times-link" className="league-type" href="https://www.gateofheavenstbrigid.org/#MassTimes">Mass Times</a>
                <img className="league-logo" src="medal.png"></img>
              </div>
              <p className="league-type-secondary"></p>
            </div>
          </div>

          <div className="league-body">
            <div style={{margin: '20px', textAlign: 'center'}}>
              <a className="league-type" style={{textTransform: 'math-auto'}} href="/our-story">The Story of League Morning Star</a>
            </div>
            <a className="btn league-btn-primary" href='prayer-intentions/new'>Write a Prayer Intention</a>
          </div>

          { anyGamesInLastDay() ? <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Recent Scores</p>
            <div className="schedule">
              { displayFinishedGamesLastDay() }
            </div>
          </div> : '' }

          <div className="league-body">
            { utils.showSignUps() ?
            <>
            <p className="league-title-standard-secondary text-align-center">Sign up for Season III</p>
            <p>With the 3rd season of League Morning Star coming up soon, we will have the biggest shake up to date. Sign up now to secure your spot!</p>
            <a type="button" className="btn btn-success" href='signup'>Click here to sign up!</a>
            <br></br>
            </>
            : '' }

            { utils.playoffsStarted(league) ?
              <>
                <p className="league-title-standard-secondary text-align-center">Playoffs</p>
                { displayPlayoffsButtons() }
                <div className="league-table-container">
                  { displayPlayoffs() }
                </div>
              </> :
              <>
                <p className="league-title-standard-secondary text-align-center">Upcoming Games</p>
                <a className="league-type league-mini-header-link" href="/schedule">View All Games</a>
                <div className="league-table-container">
                  { displayGamesThisWeek() }
                </div>

                { utils.showPlayoffBracket() ?
                <>
                  <p className="league-title-standard-secondary text-align-center">Playoffs</p>
                  { displayPlayoffsButtons() }
                  <div className="league-table-container">
                    { displayPlayoffs() }
                  </div>
                </> : ''
                }
              </>
            }
          </div>

          <div className="league-body">
            <p className="league-title-standard text-align-center">Standings</p>

            <div className="btn-group league-radio-btn-group" role="group" aria-label="League/conference radio toggle button group">
              <input type="radio" className="btn-check" name="btnradio" id="btnLeague" autocomplete="off" defaultChecked onClick={ () => { toggleToShowLeagueStandings() }}></input>
              <label className="btn league-btn-outline-primary" for="btnLeague">League</label>

              <input type="radio" className="btn-check" name="btnradio" id="btnConference" autocomplete="off" onClick={ () => { toggleToShowConferenceStandings() }}></input>
              <label className="btn league-btn-outline-primary" for="btnConference">Conference</label>
            </div>

            { showOverallStandings ?
              displayOverallStandings() :
              <>
                { displayConferenceStandings('west') }
                { displayConferenceStandings('east') }
              </>
            }

            { utils.showSeasonLeaders(league) ?
            <div className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Season Leaders</b></p>
              { showTopSeasonStats('points', SEASON) }
              { showTopSeasonStats('rebounds', SEASON) }
              { showTopSeasonStats('assists', SEASON) }
              { showTopSeasonStats('blocks', SEASON) }
              { showTopSeasonStats('steals', SEASON) }
            </div> : ''
            }
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Homepage;
